import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { AnimationOnScroll } from 'react-animation-on-scroll';


const HeroSectionStyle = styled.div`
    display: grid;
    //dodane
    position: relative;
    width: 100wh;
    height: 80vh;

    /* .video{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: brightness(65%);
        object-fit: cover;
        z-index: -1;
    } */
    .img{
        filter: brightness(75%);
        height: 100%;
        width: 100%;
    }
    ${({theme})=> theme.media.tablet}{
        height: 80vh;
        .img{
            height: 100%;
        }
         
  }
`

const TextHero = styled.div`
    color: white;
    grid-area: 1/1;
    position: relative;
    place-items: center center;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 4;
    div{
        /* background-color: rgb(36, 36, 36, 30%); */
        padding: 20px;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        h1{
            text-transform: uppercase;
            text-shadow: 4px 4px 6px rgba(66, 68, 90, 1);
            margin: 0;
            font-size: 25px;
            font-weight: 800;
        }
        h2{
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 400;
        }
        p{
            font-weight: lighter;
            font-size: 12px;
            span{
                font-weight: bolder;
            }
        }
    }

    ${({theme})=> theme.media.tablet}{
        div{
            h1{
                font-size: 60px;
            }
            h2{
                font-size: 40px;
            }
            p{
              font-size: 24px;
            }
        }
    }
`
const ButtonZapisy = styled.button`
    margin:  20px 10px;
    color: white;
    font-size: 0.8em;
    border: 1px solid #34D277;
    background-color: #34D277;
    font-weight: bold;

    padding: 10px 10px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    transition: 0.6s linear;
    width: 150px;


    &:hover{
         -webkit-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        -moz-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        color: #34D277;
        background-color: transparent;
    }

    ${({theme})=> theme.media.tablet}{
        width: 250px;
        font-size: 1em;
        padding: 15px 20px;
        border: 3px solid #34D277;
        color: white;
    }
`

// const HeroImage = styled(StaticImage)`
//     display: none;
    
//     ${({theme})=> theme.media.tablet}{
//          display: none;
//         }
// `
const HeroSection = () => {
    return (
        <HeroSectionStyle>
                    {/* You can use a GatsbyImage component if the image is dynamic */}
                    <StaticImage
                        placeholder="blurred"
                        alt="more ultra race"
                        // transformOptions={{ fit: "cover"}}
                        style={{
                        gridArea: "1/1",
                        // You can set a maximum height for the image, if you wish.
                        // maxHeight: 600,
                        height: "100%",
                        }}
                        layout="constrained"
                        // You can optionally force an aspect ratio for the generated image
                        // aspectRatio={5 / 2}
                        // This is a presentational image, so the alt should be an empty string
                        // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                        src="../../assets/images/hero7.png"
                        
                        formats={["auto", "webp", "avif"]}
                        className="img"
                    />
                    <TextHero >
                        {/* Any content here will be centered in the component */}                
                        <AnimationOnScroll animateIn="animate__backInUp" duration={2}>
   
                            <h1>Przeżyj rowerową przygodę</h1>
                            <h2>wśród roztoczańskich krajobrazów</h2>
                            {/* <h1>Rowerowa przygoda</h1>
                            <h2>wśród roztoczańskich krajobrazów</h2> */}
                            <p>Wybierz swój dystans <span>120/350/590km</span></p>
                            {/* <p>Relacja na żywo</p> */}
                            <Link to='https://b4sportonline.pl/ultra_race/'><ButtonZapisy>jade roztocze!</ButtonZapisy></Link>
                            {/* <Link to='https://urrclassico24.bbtracker.pl'><ButtonZapisy>classico</ButtonZapisy></Link>
                            <Link to='https://urrpiccolo24.bbtracker.pl'><ButtonZapisy>piccolo</ButtonZapisy></Link> */}
                        
                        </AnimationOnScroll>              
                    </TextHero>
                </HeroSectionStyle>
    );
}

export default HeroSection;
